<
<template>
  <div :class="b()"
       :style="styleSizeName" @mousewheel.prevent="handleMousewheel">
    <div :ref="id" :style="styleChartName" v-if="isEchart"></div>
    <div :class="b('map')" v-else>
      <div :class="b('box')"
           :style="styleImgName"
           @mousedown="disabled?false:handleMouseDown($event)"
           @mousemove="disabled?false:handleMouseMove($event)"
           @mouseup="disabled?false:handleMouseUp($event)">
        <img :class="b('bg')" :src="computedImgUrl(img)" ref="img" draggable="false"/>
        <span :class="b('location')" v-for="(item,index) in location"
              :style="{left:setPx(item.x),top:setPx(item.y)}"></span>
      </div>
    </div>
  </div>
</template>

<script>
import create from "../../create";

export default create({
  name: "map",
  data() {
    return {
      bannerCount: 0,
      bannerCheck: "",
      move: false,
      step: 1,
      startLeft: 0,
      startTop: 0,
      baseScale: 0,
      left: 0,
      top: 0,
      centerData: [],
      zoomData: 1
    };
  },
  watch: {
    mapData() {
      this.updateChart();
    },
    dataChartLen() {
      this.setBanner();
    },
    bannerTime() {
      this.setBanner();
    },
    banner: {
      handler() {
        this.setBanner();
      },
      immediate: true
    },
    type() {
      if (this.isEchart) {
        this.$nextTick(() => {
          this.init();
          this.updateData();
        });
      }
    },
    width() {
      this.updateData();
    },
    height() {
      this.updateData();
    },
    zoom: {
      handler() {
        this.zoomData = this.zoom;
      },
      immediate: true
    },
    scale: {
      handler(val) {
        this.baseScale = val;
      },
      immediate: true
    }
  },
  computed: {
    zoomShow() {
      return this.option.zoomShow || 1;
    },
    zoom() {
      return this.option.zoom || 1;
    },
    mapData() {
      return this.option.mapData || {};
    },
    borderWidth() {
      return this.option.borderWidth || 3;
    },
    borderColor() {
      return this.option.borderColor || "#389BB7";
    },
    areaColor() {
      return this.option.areaColor || "#0c162f";
    },
    empColor() {
      return this.option.empColor || "#fff";
    },
    empAreaColor() {
      return this.option.empAreaColor || "yellow";
    },
    color() {
      return this.option.color || "#fff";
    },
    roam() {
      return this.vaildData(this.option.roam, true)
    },
    fontSize() {
      return this.option.fontSize || 24;
    },
    isEchart() {
      return this.type === 0;
    },
    bannerTime() {
      return this.option.bannerTime || 3000;
    },
    banner() {
      return this.option.banner;
    },
    scale() {
      return this.option.scale || 100;
    },
    styleImgName() {
      return {
        marginLeft: this.setPx(this.left),
        marginTop: this.setPx(this.top),
        transform: `scale(${this.baseScale / 100}, ${this.baseScale / 100})`
      };
    },
    location() {
      return this.option.location || [];
    },
    img() {
      return this.option.img;
    },
    type() {
      return this.option.type;
    },
    locationData() {
      return (this.dataChart || []).map(ele => {
        ele.zoom = ele.zoom || 1;
        const zoomData = this.zoomData < 1 ? 1 : this.zoomData;
        return Object.assign(
            (() => {
              if (ele.zoom <= zoomData) {
                return {
                  name: ele.name
                };
              }
              return {};
            })(),
            {
              value: [ele.lng, ele.lat, ele.value]
            }
        );
      });
    }
  },
  destroyed() {
    if (this.bannerCheck) {
      clearInterval(this.bannerCheck);
    }
  },
  methods: {
    docMouseUp() {
      window.document.onmouseup = e => {
        window.document.onmousemove = undefined;
        this.move = false;
      };
    },
    handleMouseDown(e) {
      this.move = true;
      this.startLeft = e.clientX;
      this.startTop = e.clientY;
      this.docMouseUp();
    },
    handleMouseMove(e) {
      if (this.move) {
        window.document.onmousemove = e => {
          const startX = e.clientX;
          const startY = e.clientY;
          this.left = this.left + (startX - this.startLeft) * this.step;
          this.top = this.top + (startY - this.startTop) * this.step;
          this.startLeft = startX;
          this.startTop = startY;
        };
      }
    },
    handleMouseUp() {
      this.move = false;
    },
    handleMousewheel(e) {
      const type = e.deltaY;
      if (type > 0) {
        this.baseScale = this.baseScale + 10;
      } else {
        this.baseScale = this.baseScale - 10;
      }
    },
    resetBanner() {
      this.$nextTick(() => {
        this.myChart.dispatchAction({
          type: "hideTip"
        });
        // this.myChart.dispatchAction({
        //   type: "downplay"
        // });
      });
    },
    setBanner() {
      // 实现有问题，无法获取地图的实际坐标系
      if (window.echarts && window.echarts.version && window.echarts.version >= '5.2.2') {
        const self = this;
        let dataChartLength = self.dataChart && self.dataChart.length > 0 && self.dataChart[0].features.length || 0;
        let features = self.dataChart && self.dataChart.length > 0 && self.dataChart[0].features;
        clearInterval(this.bannerCheck);
        if (this.banner && features && dataChartLength > 0) {
          this.bannerCheck = setInterval(() => {
            const curr = self.bannerCount % dataChartLength;
            const pre = curr - 1 < 0 ? dataChartLength - 1 : curr - 1;
            // echarts版本5.1.0开始支持geoIndex
            // https://echarts.apache.org/zh/api.html#action.tooltip.showTip
            self.myChart.dispatchAction({
              type: "hideTip",
              // dataIndex: curr,
              geoIndex: 0,
              name: features[pre] && features[pre].properties && features[pre].properties.name
            });
            self.myChart.dispatchAction({
              type: "showTip",
              // dataIndex: curr,
              geoIndex: 0,
              name: features[curr] && features[curr].properties && features[curr].properties.name
            });
            self.myChart.dispatchAction({
              type: "downplay",
              geoIndex: 0,
              name: features[pre] && features[pre].properties && features[pre].properties.name
            });
            self.myChart.dispatchAction({
              type: "highlight",
              // dataIndex: curr,
              geoIndex: 0,
              name: features[curr] && features[curr].properties && features[curr].properties.name
            });
            self.bannerCount += 1;
          }, this.bannerTime);
        }
      }
    },
    // 修复数据来源多个的冲突
    renderChart(mapData, forceUpdate) {
      const self = this;
      window.echarts.registerMap("HK", mapData);
      const option = {
        tooltip: {
          show: true,
          trigger: 'item',
          position: "inside",
          backgroundColor: self.option.tipBackgroundColor || "rgba(0,0,0,1)",
          textStyle: {
            fontSize: self.option.tipFontSize,
            color: self.option.tipColor || "red"
          }
        },
        geo: Object.assign(
            (() => {
              if (!this.validatenull(this.centerData)) {
                return {
                  center: this.centerData
                };
              }
              return {};
            })(),
            {
              map: "HK",
              zoom: this.zoomData,
              layoutCenter: ["50%", "50%"],
              layoutSize: 1200,
              roam: this.roam,
              label: {
                emphasis: {
                  show: false
                },
                show: true,
                fontSize: this.fontSize,
                color: this.color
              },
              left: this.option.gridX,
              top: this.option.gridY,
              right: this.option.gridX2,
              bottom: this.option.gridY2,
              emphasis: {
                label: {
                  color: this.empColor
                },
                itemStyle: {
                  areaColor: this.empAreaColor
                }
              },
              itemStyle: {
                borderWidth: this.borderWidth,
                borderColor: this.borderColor,
                areaColor: this.areaColor
              },
              tooltip: {
                show: true,
                trigger: 'item',
                position: "inside",
                formatter: function (params) {
                  return params.name;
                },
                backgroundColor: self.option.tipBackgroundColor || "rgba(0,0,0,1)",
                textStyle: {
                  fontSize: self.option.tipFontSize,
                  color: self.option.tipColor || "red"
                }
              }
            }
        ),
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            showEffectOn: "emphasis",
            rippleEffect: {
              brushType: "fill",
              scale: 4
            },
            symbolSize: this.fontSize,
            hoverAnimation: true,
            data: this.locationData,
            label: {
              show: true,
              position: ["130%", "0"],
              fontSize: this.fontSize,
              color: this.color,
              formatter: params => {
                return params.name;
              }
            },
            itemStyle: {
              color: this.color
            },
            emphasis: {
              label: {
                show: true,
                fontSize: this.fontSize + 20,
                color: this.option.empColor
              },
              itemStyle: {
                color: this.option.empColor
              }
            }
          }
        ]
      };
      if (this.banner && window.echarts && window.echarts.version && window.echarts.version >= '5.2.2') {
        this.myChart.off("mouseover");
        this.myChart.off("mouseout");
        // this.myChart.off("georoam");

        this.myChart.on("mouseover", () => {
          clearInterval(self.bannerCheck);
          self.resetBanner();
        });
        this.myChart.on("mouseout", () => {
          // console.log(params)
          self.bannerCount = 0;
          self.setBanner();
        });
        /*this.myChart.on("georoam", e => {
          const option = self.myChart.getOption();
          const geo = option.geo[0];
          self.centerData = geo.center;
          self.zoomData = geo.zoom;
          if (self.zoomData < 1) self.zoomData = 1;
        });*/
      }

      if (forceUpdate) {
        this.myChart.clear();
      }
      this.myChart.resize();
      this.myChart.setOption(option, true);
    },
    updateChart(forceUpdate = false) {
      const dataChart = this.dataChart && this.dataChart.length > 0 && this.dataChart[0];
      let self = this;
      this.$axios(this.computedImgUrl(this.mapData)).then(res => {
        const data = res.data;
        const optionData = this.deepClone(data);
        // 修复数据来源多个的冲突，动态数据优先
        self.renderChart(dataChart || optionData, forceUpdate)
      })

    }
  }
});
</script>



