<template>
  <el-dialog title="图库"
             width="80%"
             :close-on-click-modal="false"
             :visible.sync="imgVisible">
    <div style="margin:0 auto;">
      <el-upload class="upload-demo"
                 :on-success="onSuccess"
                 :show-file-list="false"
                 :headers="headers"
                 :action="websiteUri+'/visual/put-file'+(type?'/'+type:'')"
                 multiple
                 list-type="picture">
        <el-button size="small"
                   icon="el-icon-upload"
                   type="primary">点击上传
        </el-button>
      </el-upload>
    </div>
    <el-scrollbar class="imgList">
      <img :src="computedImgUrl(item.link)"
           :style="styleName"
           class="html2canvasHidden"
           @click="handleSetimg(item.link)"
           v-for="(item,index) in imgOption"
           :key="index"/>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import {getImgList} from '@/api/visual';
import {imgOption} from '@/option/config'
import {url, apiUri} from "../../config";

export default {
  data() {
    return {
      headers: {},
      imgVisible: false,
      imgObj: '',
      type: '',
      imgActive: 0,
      imgOption: imgOption,
      imgTabs: [],
      apiUri: apiUri
    }
  },
  computed: {
    styleName() {
      if (this.type === 'background') {
        return {
          width: '200px'
        }
      }
      return {}
    },
    websiteUri() {
      // console.log(this.$website, url)
      return url
    }
  },
  watch: {
    type: {
      handler() {
        if (this.type === 'background') {
          this.imgActive = 0;
        } else if (this.type == 'border') {
          this.imgActive = 1;
        } else {
          this.imgActive = 2;
        }
      },
      immediate: true
    }
  },
  created() {
    this.headers['Authorization'] = localStorage.getItem("token") || ''
  },
  methods: {
    onSuccess(res) {
      if (res && res.data && res.data.link) {
        const url = res.data.link;
        this.imgOption.unshift({
          label: url,
          link: url
        });
      }
    },
    openImg(item, type) {
      this.type = type;
      this.imgObj = item;
      this.getImgList();
      this.imgVisible = true;
    },
    handleSetimg(item) {
      this.imgVisible = false;
      this.$emit('change', item, this.imgObj);
    },
    getImgList() {
      this.imgOption = []
      getImgList(this.type).then(res => {
        this.imgOption = res.data.data
      })
    },
  }
}
</script>

<style>
</style>
